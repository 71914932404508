import React, { useEffect, useState } from 'react'
import dateFns from 'date-fns'

import { urlFor } from './utils/url_helper'

import Modal from './layout/modal'
import BookingButton from './booking_button'
import FriendSpots from './friend_spots'
import BookingFlow from './BookingFlow'

const InviteButton = ({ id }) => {
  const [shareSupport, setShareSupport] = useState(true)

  const openNativeShare = () => {
    if (navigator.share) {
      navigator.share({ title: 'Motion', url: urlFor(id) })
    } else {
      setShareSupport(false)
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(urlFor(id))
  }

  if (shareSupport) {
    return (
      <button
        className="btn btn-block btn-sm btn-outline-secondary"
        onClick={openNativeShare}
        children="Invite Friends"
      />
    )
  } else {
    return (
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          aria-describedby="button-share-link"
          value={urlFor(id).replace('https://', '')}
          readOnly
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-share-link"
            onClick={copyLink}
          >
            Copy Link
          </button>
        </div>
      </div>
    )
  }
}

const SelectedClassModal = (props) => {
  const {
    currentUser,
    refreshSelf,
    classSession,
    setSelectedClassSession,
  } = props

  const [bookingActive, setBookingActive] = useState(false)
  const [imageUrl, setImageUrl] = useState(
    classSession && classSession.instructor_picture_url
  )

  useEffect(() => {
    if (classSession && classSession.id) {
      setImageUrl(classSession.instructor_picture_url)
      fetch(
        `https://barrysbootcamp.marianatek.com/api/customer/v1/classes/${classSession.id}`
      )
        .then((response) => response.json())
        .then((response) => {
          try {
            setImageUrl(response.instructors[0].photo_urls.thumbnail_url)
          } catch (e) {}
        })
    }
  }, [classSession])

  useEffect(() => {
    setBookingActive(false)
  }, [classSession])

  if (!classSession) return null

  const {
    id,
    time,
    date,
    classroom_display,
    instructor_names,
    location_name,
    reserved,
    class_title,
    price,
    equipment,
    duration,
    friends,
  } = classSession

  const InstructorPhoto = () => {
    return (
      <img
        src={imageUrl}
        alt=""
        className="rounded-circle float-right"
        style={{ width: 100, height: 100, objectFit: 'cover' }}
      />
    )
  }

  const PriceTag = () => {
    if (!price) return null

    return <span className="badge badge-secondary mr-1 mb-1">{price}</span>
  }

  const DurationTag = () => {
    if (!duration) return null

    return (
      <span className="badge badge-secondary mr-1 mb-1">{duration} min</span>
    )
  }

  const EquipmentTag = () => {
    if (!equipment) return null

    const equipmentName = () => {
      switch (equipment) {
        case 'none':
          return 'Bodyweight'
        case 'bands':
          return 'Bands'
        case 'weights':
          return 'Weights'
        default:
          return ''
      }
    }

    return (
      <span className="badge badge-secondary mr-1 mb-1">{equipmentName()}</span>
    )
  }

  return (
    <Modal
      title={'selectedClassModal'}
      onClose={() => {
        setSelectedClassSession(null)
      }}
    >
      <div className="row">
        <div className="col-sm-12">
          {imageUrl && <InstructorPhoto />}
          <p className="lead" style={{ marginBottom: '0.5rem' }}>
            {time} {dateFns.format(dateFns.parse(date), 'dddd')}
          </p>
          {class_title && (
            <p className="lead" style={{ marginBottom: '0.3rem' }}>
              {class_title}
            </p>
          )}
          <p style={{ marginBottom: '0.3rem' }}>
            {instructor_names && instructor_names.join(', ')}
          </p>
          <p style={{ marginBottom: '0.3rem' }}>{classroom_display}</p>
          <h6>
            <PriceTag />
            <DurationTag />
            <EquipmentTag />
          </h6>
          <h6>
            <FriendSpots friends={friends} />
          </h6>
        </div>
      </div>
      {reserved && <InviteButton id={id} />}
      <BookingButton
        {...classSession}
        active={bookingActive}
        onClick={() => setBookingActive(true)}
      />
      {bookingActive && (
        <BookingFlow
          id={id}
          currentUser={currentUser}
          refreshSelf={refreshSelf}
        />
      )}
    </Modal>
  )
}

export default SelectedClassModal
