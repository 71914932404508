import React, { useLayoutEffect } from 'react'
import { Modal as BootstrapModal } from 'bootstrap'

const Modal = ({ title, onClose, children }) => {
  useLayoutEffect(() => {
    const modalElement = document.getElementById(title)
    const modal = new BootstrapModal(modalElement)

    const handleHide = () => {
      onClose()
    }

    modalElement.addEventListener('hidden.bs.modal', handleHide)

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleHide)
      modal.dispose()
    }
  }, [onClose, title])

  return (
    <div id={title} className="modal fade" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close d-none d-sm-block"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ padding: '0.5rem 1rem' }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              className="close d-block d-sm-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                padding: '0.5rem 1rem',
                margin: '-1rem auto -1rem -1rem',
              }}
            >
              <span aria-hidden="true">&larr;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
