import React, { useState } from 'react'
import dateFns from 'date-fns'
// import { loadStripe } from '@stripe/stripe-js'

import { startCheckout } from './api'

// const stripePromise = loadStripe('pk_live_RTyDX3rbJuVkQScvU48fr5Fn00wIQj2l9p')
// const stripePromise = loadStripe('pk_test_Nc9wUB0fm9glCJtxnrFoO5k800wtUf7YjW')

const GoToRoom = ({ id }) => (
  <a
    href={`/room/${id}`}
    className="btn btn-block btm-sm btn-outline-danger active bw-2"
    children="Go to Room ⇨"
  />
)

const YoureIn = () => (
  <button className="btn btn-block btm-sm btn-danger" children="You're in 💪" />
)

const Reserve = ({ onClick, loading }) => (
  <button
    onClick={onClick}
    className="btn btn-block btm-sm btn-outline-danger bw-2"
    type="button"
    disabled={loading}
  >
    {loading ? (
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
    ) : (
      'Reserve'
    )}
  </button>
)

const CheckoutButton = ({ id, reserved, start_datetime }) => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    const { sessionId } = await startCheckout(id)
    const stripe = await stripePromise

    const { error } = await stripe.redirectToCheckout({ sessionId })
  }

  if (reserved) {
    const startTime = dateFns.parse(start_datetime)

    if (dateFns.isPast(dateFns.addMinutes(startTime, -15))) {
      return <GoToRoom id={id} />
    }
    return <YoureIn />
  }

  return <Reserve onClick={onClick} loading={loading} />
}

export default CheckoutButton
