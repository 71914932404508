import React, { useState, useCallback } from 'react'
import { format, addDays, parse } from 'date-fns'
import DateButton from './date_button'

const Datepicker = ({ selectedDate, update, availableDates }) => {
  const today = new Date()
  const initialWeek = Array.from({ length: 8 }, (_, i) =>
    format(addDays(today, i), 'YYYY-MM-DD')
  )

  const [week, setWeek] = useState(0)
  const [days, setDays] = useState(initialWeek)

  const updateDays = useCallback(
    (weekOffset) => {
      const baseDate = parse(selectedDate, 'YYYY-MM-DD', new Date())
      const newDays = Array.from({ length: 8 }, (_, i) =>
        format(addDays(baseDate, weekOffset * 7 + i), 'YYYY-MM-DD')
      )
      setDays(newDays)
    },
    [selectedDate]
  )

  const goNextWeek = useCallback(() => {
    if (week === 2) return

    const newSelectedDate = format(
      addDays(parse(selectedDate, 'YYYY-MM-DD', new Date()), 7),
      'YYYY-MM-DD'
    )
    update(newSelectedDate)

    setWeek((prevWeek) => {
      const newWeek = prevWeek + 1
      updateDays(newWeek)
      return newWeek
    })
  }, [week, selectedDate, update, updateDays])

  const goPrevWeek = useCallback(() => {
    if (week === 0) return

    const newSelectedDate = format(
      addDays(parse(selectedDate, 'YYYY-MM-DD', new Date()), -7),
      'YYYY-MM-DD'
    )
    update(newSelectedDate)

    setWeek((prevWeek) => {
      const newWeek = prevWeek - 1
      updateDays(newWeek)
      return newWeek
    })
  }, [week, selectedDate, update, updateDays])

  return (
    <div>
      <div className="row mb-3 mt-3">
        <div className="col d-flex justify-content-between">
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={goPrevWeek}
            disabled={week === 0}
          >
            <strong>&laquo;</strong>
          </button>
          <span style={{ flex: 1, textAlign: 'center' }}>
            {format(parse(selectedDate, 'YYYY-MM-DD', new Date()), 'MMMM DD')}
          </span>
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={goNextWeek}
            disabled={week === 2}
          >
            <strong>&raquo;</strong>
          </button>
        </div>
      </div>
      <div className="row border-bottom border-bottom-thick mb-1 pb-2">
        <div className="col d-flex justify-content-between">
          {days.map((date) => (
            <DateButton
              update={update}
              date={date}
              key={date}
              available={availableDates.has(date)}
              selected={date === selectedDate}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Datepicker
